import { Theme, useMediaQuery, Box, Divider, Drawer } from '@mui/material';
import { InsertDriveFile, Loyalty, Star } from '@mui/icons-material';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import NavSection from './NavSection';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'Plaisirs & Privilèges',
    items: [
      {
        title: 'Factures',
        icon: <InsertDriveFile fontSize="small" />,
        path: '/dashboard/invoice-scans',
      },
      {
        title: 'Participants',
        icon: <UsersIcon fontSize="small" />,
        path: '/dashboard/participants',
        tabParams: ['only', 'sponsors'],
      },
      {
        title: 'Bannières',
        path: '/dashboard/promotions-banners',
        icon: <Star fontSize="small" />,
      },
      {
        title: 'Promotions & Privilèges',
        path: '/dashboard/loyalties/privileges',
        icon: <Loyalty fontSize="small" />,
        tabParams: ['all', 'privileges', 'rewards', 'bonus-points'],
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = props => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          {sections.map(section => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary">
      {content}
    </Drawer>
  );
};
export default DashboardSidebar;
