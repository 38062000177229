import { Auth0ClientOptions } from '@auth0/auth0-spa-js/src/global';

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config: Auth0ClientOptions = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  useRefreshTokens: true,
  advancedOptions: {
    defaultScope: 'openid profile email',
  },
  ui_locales: 'fr-CA',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};
