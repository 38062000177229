import { FormControlLabel, Grid, Switch } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoyaltyConditionsContext } from '../context/LoyaltyConditionsContext';
import { WeekDaySelection } from './WeekDaySelection';

export const TimeCondition: FC = () => {
  const { i18n } = useTranslation();
  const context = useContext(LoyaltyConditionsContext);

  const { hasRestosDollarsCondition, hasTimeCondition, setHasTimeCondition } = context;

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={hasTimeCondition}
              data-testid="switch-conditionTime"
              disabled={hasRestosDollarsCondition}
              onChange={event => setHasTimeCondition(event.target.checked)}
            />
          }
          label={`${i18n.t('loyaltyDetails.form.specificDay')}`}
        />
      </Grid>
      {hasTimeCondition && (
        <WeekDaySelection
          validDaysInputName="conditions.timeConstraint.validDays"
          startTimeInputName="conditions.timeConstraint.startTime"
          endTimeInputName="conditions.timeConstraint.endTime"
          sx={{ paddingLeft: '65px', paddingTop: '16px' }}
        />
      )}
    </>
  );
};
