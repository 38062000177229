import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Tried to type correctly but can't seem to make it work with library.
  control: any;
} & TextFieldProps;

export const FormInputText: FC<Props> = (props): JSX.Element => {
  const { control, name, required, ...rest } = props;
  return (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => <TextField {...field} {...rest} required={required} value={field.value || ''} />}
    />
  );
};
