import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import i18n from 'i18next';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useLoadingContext } from 'src/contexts/LoadingContext';
import { deletePromotionBanner, updatePromotionOrder, useAllPromotionBanner } from '../../../actions/bannerActions';
import Scrollbar from '../../Scrollbar';
import SearchBar from '../../searchBar/SearchBar';
import { Field, IFilter } from '../../searchBar/types/searchBarTypes';

const fields: Field[] = [
  {
    prop: 'name',
    label: i18n.t('promotionBannerList.name'),
  },
  {
    prop: 'link',
    label: i18n.t('promotionBannerList.url'),
  },
];

const BannerListTable: FC = () => {
  const [filter, setFilter] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogDeleteContext, setDialogDeleteContext] = useState<{ id: string; name: string; order?: number }>();
  const { data: promotionBanners, count, mutate, isLoading } = useAllPromotionBanner({ page, limit, filter });
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const handleClickOpen = ({ id, name, order }: { id: string; name: string; order?: number }) => {
    setDialogDeleteContext({ id, name, order });
    setIsDialogOpen(true);
  };

  const handleCloseDelete = () => {
    setIsDialogOpen(false);
    setDialogDeleteContext(null);
  };

  const handleDeleteClick = async () => {
    handleCloseDelete();
    await deletePromotionBanner(dialogDeleteContext.id);
    await mutate([...promotionBanners.filter(promotionBanner => promotionBanner.id !== dialogDeleteContext.id)]);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value, 10);
    setPage(Math.floor((page * limit) / newLimit));
    setLimit(newLimit);
  };

  const handleCallBack = useCallback((_: IFilter[], _filterParams: string): void => {
    setPage(0);
    setFilter(_filterParams);
  }, []);

  const handleOrderChange = async (id: string, order: number): Promise<void> => {
    await updatePromotionOrder(id, order);
    await mutate();
  };

  return (
    <Card>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {i18n.t('promotionBannerList.dialogDelete.title', { name: dialogDeleteContext?.name })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{i18n.t('promotionBannerList.dialogDelete.description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="dialog-delete-button-no" onClick={handleCloseDelete}>
            {i18n.t('promotionBannerList.dialogDelete.no')}
          </Button>
          <Button data-testid="dialog-delete-button-yes" onClick={handleDeleteClick}>
            {i18n.t('promotionBannerList.dialogDelete.yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', m: -1, p: 2 }}>
        <SearchBar fields={fields} onFilterChange={handleCallBack} />
      </Box>
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 1150 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 100 }} />
                <TableCell>{i18n.t('promotionBannerList.name')}</TableCell>
                <TableCell>{i18n.t('promotionBannerList.url')}</TableCell>
                <TableCell style={{ width: 200 }} align="center">
                  {i18n.t('invoiceScanList.actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotionBanners.map(({ id, link, name, thumbnail, order }, i) => (
                <TableRow hover key={id} data-testid="row-data">
                  <TableCell data-testid="thumbnail">
                    <img alt={name} src={`data:image/png;base64,${thumbnail}`} />
                  </TableCell>
                  <TableCell data-testid="name">{name}</TableCell>
                  <TableCell data-testid="link">{link}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleOrderChange(id, order - 1)} disabled={i === 0} data-testid="moveUpPosition">
                      <ArrowUpward fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => handleOrderChange(id, order + 1)} disabled={i === count - 1} data-testid="moveDownPosition">
                      <ArrowDownward fontSize="small" />
                    </IconButton>
                    <IconButton data-testid="delete-button" onClick={() => handleClickOpen({ id, name, order })}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 40]}
      />
    </Card>
  );
};

export default BannerListTable;
