import { Field, IFilter, OperatorEnum } from '../types/searchBarTypes';

export const paramsToFilter = (params: string, fields: Field[]): IFilter[] => {
  if (!params) return [];
  return params.split('&').reduce((acc, param) => {
    const [key, valuesEncoded] = param.split('=');
    const values = decodeURIComponent(valuesEncoded).split(',');
    const operator = OperatorEnum.REGEX;
    const { label } = fields.find(({ prop }) => prop === key) ?? {};
    const filters: IFilter[] = values.map(value => ({ key, value, label, operator }));

    return acc.concat(filters);
  }, []);
};

export const filterToParams = (filter: IFilter[]): URLSearchParams => <URLSearchParams>filter.reduce((acc, param) => {
    if (acc[param.key]) {
      acc[param.key] = `${acc[param.key]},${encodeURIComponent(param.value)}`;
    } else {
      acc[param.key] = encodeURIComponent(param.value);
    }
    return acc;
  }, {});

export const formatUrlMongoParams = (filters: IFilter[] = []): string => {
  const params = filters.map((filter: IFilter) => {
    const value = decodeURIComponent(`${filter.value}`);
    if (filter.hasNotRegexOperator) return `{"${filter.key}":{"${filter.operator}": { "$regex":"${value}","$options":"i"}}}`;
    if (filter.operator) {
      return `{"${filter.key}":{"${filter.operator}":"${value}","$options":"i"}}`;
    }
    return `{"${filter.key}":"${value}"}`;
  });

  if (!params.length) return '';
  return encodeURIComponent(`{"$and":[${params.join(',')}]}`);
};
