import axios from 'axios';
import { Loyalty } from '../types/loyalty';
import { LoyaltyType } from 'src/components/loyalty/enum/LoyaltyType';

export const deletePrivilege = async (id: string): Promise<void> => axios.delete(`dashboard/${LoyaltyType.PRIVILEGES}/${id}`);

export const createPrivilege = async (data: Loyalty): Promise<void> => {
  await axios.post<Loyalty>(`dashboard/${LoyaltyType.PRIVILEGES}`, data);
};

export const updatePrivilege = async (loyaltyTemplateId: string, data: Loyalty): Promise<void> => {
  await axios.put<Loyalty>(`dashboard/${LoyaltyType.PRIVILEGES}/${loyaltyTemplateId}`, data);
};
