import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enTranslation } from './i18n/en';
import { frTranslation } from './i18n/fr';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});
