import axios from 'axios';
import { Loyalty } from '../types/loyalty';
import { LoyaltyType } from 'src/components/loyalty/enum/LoyaltyType';

const url = 'dashboard/bonus-points';

export const deleteBonusPoints = async (id: string): Promise<void> => axios.delete(`dashboard/${LoyaltyType.BONUS_POINTS}/${id}`);

export const createBonusPoints = async (data: Loyalty): Promise<void> => {
  await axios.post<Loyalty>(url, data);
};

export const updateBonusPoints = async (loyaltyTemplateId: string, data: Loyalty): Promise<void> => {
  await axios.put<Loyalty>(`${url}/${loyaltyTemplateId}`, data);
};
