import { FC, useEffect, useState } from 'react';
import Dropzone, { FileWithPath } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import DuplicateIcon from '../../icons/Duplicate';
import XIcon from '../../icons/X';
import bytesToSize from '../../utils/bytesToSize';
import { useTranslation } from 'react-i18next';

type Props = {
  image?: FileWithPath; // File with path when uploading file in create mode
  imageBlob?: Blob; //  Blob from stream image when editing form
  imageUrl?: string; //  Blob from stream image when editing form
  onRemove?: () => void;
} & DropzoneOptions;

export const UploadDropzone: FC<Props> = props => {
  const { accept, image, maxFiles, maxSize, minSize, imageBlob, onDrop, onRemove, imageUrl } = props;
  const [preview, setPreview] = useState('');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // eslint-disable-next-line consistent-return -- This is how useEffect cleanup works in React.
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  return (
    <div>
      {!image && !(imageBlob || imageUrl) && (
        <Dropzone maxSize={maxSize} minSize={minSize} onDrop={onDrop} accept={accept} maxFiles={maxFiles} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <Box
              sx={{
                alignItems: 'center',
                border: 1,
                borderRadius: 1,
                borderColor: 'divider',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                outline: 'none',
                p: 6,
                ...(isDragActive && {
                  backgroundColor: 'action.active',
                  opacity: 0.5,
                }),
                '&:hover': {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                  opacity: 0.5,
                },
              }}
              data-testid="image-dropzone"
              {...getRootProps()}>
              <input {...getInputProps()} />

              <Box sx={{ '& img': { width: 100 } }}>
                <img alt="Select file" src="/static/undraw_add_file2_gvbb.svg" />
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography color="textPrimary" variant="h6">
                  {i18n.t('promotionBannerCreate.form.instruction.main')}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography color="textPrimary" variant="body1">
                    {i18n.t('promotionBannerCreate.form.instruction.secondary')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Dropzone>
      )}

      {image && (
        <Box sx={{ mt: 2 }}>
          <List>
            <ListItem key={image.path} sx={{ border: 1, borderColor: 'divider', borderRadius: 1, '& + &': { mt: 1 } }}>
              <ListItemIcon>
                <DuplicateIcon fontSize="small" />
              </ListItemIcon>
              <img src={preview} alt="preview" width={180.125} style={{ marginRight: 12 }} />
              <ListItemText
                primary={image.name}
                primaryTypographyProps={{
                  color: 'textPrimary',
                  variant: 'subtitle2',
                }}
                secondary={bytesToSize(image.size)}
              />
              <Tooltip title={i18n.t('button.remove')}>
                <IconButton edge="end" onClick={onRemove}>
                  <XIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItem>
          </List>
        </Box>
      )}
      {(imageBlob || imageUrl) && (
        <Box sx={{ mt: 2 }}>
          <List>
            <ListItem sx={{ border: 1, borderColor: 'divider', borderRadius: 1, '& + &': { mt: 1 } }}>
              <ListItemIcon>
                <DuplicateIcon fontSize="small" />
              </ListItemIcon>
              <img src={imageUrl || URL.createObjectURL(imageBlob)} alt="loyalty-template" width={180.125} />
              <ListItemText />
              <Tooltip title={i18n.t('button.remove')}>
                <IconButton edge="end" onClick={onRemove}>
                  <XIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItem>
          </List>
        </Box>
      )}
    </div>
  );
};
