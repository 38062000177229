import { lazy, Suspense } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/layout/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import type { RouteObject } from 'react-router';
import Login from './pages/authentication/Login';
import MainLayout from './components/layout/MainLayout';
import BannerList from './pages/dashboard/BannerList';
import BannerCreate from './pages/dashboard/BannerCreate';
import { LoyaltyList } from './pages/dashboard/LoyaltyList';
import { LoyaltyType } from './components/loyalty/enum/LoyaltyType';
import { LoyaltyDetails } from './components/loyalty/LoyaltyDetails';

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const InvoiceScanDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceScanDetails')));
const InvoiceScanList = Loadable(lazy(() => import('./pages/dashboard/InvoiceScanList')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const ParticipantDetails = Loadable(lazy(() => import('./pages/dashboard/ParticipantDetails')));
const ParticipantList = Loadable(lazy(() => import('./pages/dashboard/ParticipantList')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: '',
        element: <Login />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'invoice-scans',
        children: [
          {
            path: '',
            element: <InvoiceScanList />,
          },
          {
            path: ':invoiceScanId',
            element: <InvoiceScanDetails />,
          },
        ],
      },
      {
        path: 'participants',
        children: [
          {
            path: '',
            element: <ParticipantList />,
          },
          {
            path: '/dashboard/participants/only',
            element: <ParticipantList />,
          },
          {
            path: '/dashboard/participants/sponsors',
            element: <ParticipantList />,
          },
          {
            path: ':id',
            element: <ParticipantDetails />,
          },
        ],
      },
      {
        path: 'promotions-banners',
        children: [
          {
            path: '',
            element: <BannerList />,
          },
          {
            path: 'create',
            element: <BannerCreate />,
          },
        ],
      },
      {
        path: 'loyalties',
        children: [
          {
            path: '',
            element: <LoyaltyList tab={LoyaltyType.ALL} />,
          },
          {
            path: 'all',
            element: <LoyaltyList tab={LoyaltyType.ALL} />,
          },
          {
            path: 'privileges',
            element: <LoyaltyList tab={LoyaltyType.PRIVILEGES} />,
          },
          {
            path: 'rewards',
            element: <LoyaltyList tab={LoyaltyType.REWARD} />,
          },
          {
            path: 'bonus-points',
            element: <LoyaltyList tab={LoyaltyType.BONUS_POINTS} />,
          },
          {
            path: 'privileges/:promotionId',
            element: <LoyaltyDetails type={LoyaltyType.PRIVILEGES} />,
          },
          {
            path: 'bonus-points/:promotionId',
            element: <LoyaltyDetails type={LoyaltyType.BONUS_POINTS} />,
          },
          {
            path: 'create',
            element: <LoyaltyDetails />,
          },
        ],
      },
    ],
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
