import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Tried to type correctly but can't seem to make it work with library.
  control: any;
  items: { label: string | number; value: string | number }[];
} & TextFieldProps;

export const FormInputSelect: FC<Props> = (props): JSX.Element => {
  const { control, name, required, items, label, defaultValue, ...rest } = props;
  return (
    <Controller
      rules={{ required: true }}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField {...field} {...rest} select helperText={label} defaultValue={defaultValue}>
          {items.map(menuItem => (
            <MenuItem key={menuItem.value} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
