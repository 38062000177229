import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { LoginAuth0 } from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';

const platformIcons = {
  Auth0: '/static/icons/auth0.svg',
  JWT: '/static/icons/jwt.svg',
};

const Login: FC = () => {
  const { platform } = useAuth();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Log in on the internal platform
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}>
                  <img alt="Auth platform" src={platformIcons[platform]} />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}>
                {platform === 'Auth0' && <LoginAuth0 />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
