import axios from 'axios';
import { LoyaltyType } from 'src/components/loyalty/enum/LoyaltyType';
import useSWR from 'swr';
import { Loyalty } from '../types/loyalty';
import { ParamsCollector } from '../types/paramsCollector';
import { SwrAction } from '../types/swr';
import { createBonusPoints, updateBonusPoints } from './bonusPointsActions';
import { createPrivilege, updatePrivilege } from './privilegeActions';

export type FormLoyaltyData = {
  promotionId?: string;
  formData: Loyalty;
  formSwitches: {
    isSpecificDaySwitchOn: boolean;
    hasConditions: boolean;
    hasTimeCondition: boolean;
    hasRestaurantCondition: boolean;
    hasRestosDollarsCondition: boolean;
    hasSpecificProductCondition: boolean;
  };
};

export const handleLoyaltyFormSubmit = async (data: FormLoyaltyData, mode: 'editForm' | 'createForm'): Promise<Loyalty> => {
  const updatedData = await formatLoyaltyFormData(data);
  switch (updatedData.loyaltyType) {
    case LoyaltyType.BONUS_POINTS:
      if (mode === 'createForm') await createBonusPoints(updatedData);
      else await updateBonusPoints(data.promotionId, updatedData);
      break;
    case LoyaltyType.PRIVILEGES:
      if (mode === 'createForm') await createPrivilege(updatedData);
      else await updatePrivilege(data.promotionId, updatedData);
      break;
    default:
      break;
  }
  return updatedData;
};

export const formatLoyaltyFormData = (data: FormLoyaltyData): Promise<Loyalty> =>
  new Promise((resolve, reject) => {
    const {
      formData,
      formSwitches: {
        isSpecificDaySwitchOn,
        hasTimeCondition,
        hasRestaurantCondition,
        hasRestosDollarsCondition,
        hasConditions,
        hasSpecificProductCondition,
      },
    } = data;

    let updatedData = { ...formData };
    if (!isSpecificDaySwitchOn && updatedData.repetition) updatedData = { ...updatedData, repetition: null };

    if (!hasTimeCondition) updatedData = { ...updatedData, conditions: { ...updatedData.conditions, timeConstraint: null } };
    if (!hasRestaurantCondition) updatedData = { ...updatedData, conditions: { ...updatedData.conditions, restaurantConstraint: null } };

    if (!hasRestosDollarsCondition)
      updatedData = { ...updatedData, conditions: { ...updatedData.conditions, restoDollarsConstraint: null } };

    if (!hasSpecificProductCondition) updatedData = { ...updatedData, conditions: { ...updatedData.conditions, productConstraint: null } };

    if (!hasConditions) updatedData = { ...updatedData, conditions: null };

    if (updatedData.picture && typeof updatedData.picture === 'object') {
      const reader = new FileReader();
      reader.onload = async event => {
        const base64 = (event.target.result as string).split(',')[1];
        if (event.target.result) {
          resolve({ ...updatedData, picture: base64 });
        } else {
          reject();
        }
      };
      reader.readAsDataURL(updatedData.picture);
    } else {
      resolve({ ...updatedData });
    }
  });

const getKey = (type: LoyaltyType, params: ParamsCollector, sort = 'status;-updatedAt') => {
  const { page, limit, filter } = params;

  return () => {
    const urlFilters = filter ? `&filter=${filter}` : '';
    return `dashboard/${type}?${`page=${page}&limit=${limit}${urlFilters}&sort=${sort}`}`;
  };
};

export const useAllLoyalties = (type: LoyaltyType, params: ParamsCollector): SwrAction<Loyalty[]> => {
  const fetcher = (url: string) =>
    axios.get<{ data: Loyalty[]; count: number }>(url).then(res => ({
      count: res.data.count || 0,
      loyalties: res.data.data || [],
    }));

  const { data, error, mutate } = useSWR(getKey(type, params), fetcher);
  return {
    data: data?.loyalties || [],
    count: data?.count || 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useLoyaltyTemplate = (loyaltyTemplateId: string, type: LoyaltyType): SwrAction<Loyalty | null> => {
  const fetcher = (url: string) =>
    axios.get<{ data: Loyalty }>(url).then(res => {
      const loyalty = res.data.data || null;
      if (loyalty) loyalty.loyaltyType = type;
      return {
        loyalty,
      };
    });

  const { data, error, mutate } = useSWR(loyaltyTemplateId && `dashboard/${type}/${loyaltyTemplateId}`, fetcher);

  return {
    data: data?.loyalty,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
