import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Chip, IconButton, InputAdornment, Menu as MenuComp, MenuItem, Popover, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SearchIcon from '../../icons/Search';
import X from '../../icons/X';
import { v4 as uuidV4 } from 'uuid';
import { Field, IFilter, OperatorEnum } from './types/searchBarTypes';
import { FilterList } from '@mui/icons-material';
import { filterToParams, formatUrlMongoParams, paramsToFilter } from './utils/paramsFilters';
import i18n from 'i18next';

type Props = {
  fields: Field[];
  options?: {
    defaultField?: Field;
    maxFilter?: number;
  };
  onFilterChange: (filter: IFilter[], filterParams: string) => void;
  predefinedFilter?: IFilter[];
};

const SearchBar: FC<Props> = props => {
  const ITEM_HEIGHT = 56;
  const { fields, options, onFilterChange, predefinedFilter } = props;
  const [firstField] = fields;

  const [selectedField, setSelectedField] = useState<Field>(options?.defaultField ?? firstField);
  const [query, setQuery] = useState<string>('');
  const [filterFields, setFilterFields] = useState<IFilter[]>([]);
  const [anchorElMenuField, setAnchorElMenuField] = useState(null);
  const [anchorElAutocompleteField, setAnchorElAutocompleteField] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const paramsStr = searchParams.toString();
    const filters = paramsToFilter(paramsStr, fields);
    setFilterFields(filters);
    onFilterChange(filters, formatUrlMongoParams([...filters, ...(predefinedFilter ?? [])]));
  }, [searchParams, fields, onFilterChange, predefinedFilter]);

  const openMenuField = Boolean(anchorElMenuField);
  const openAutocompleteField = Boolean(anchorElAutocompleteField);
  const disabledFilter = filterFields.length >= options?.maxFilter;

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => setQuery(event.target.value);

  const hasTextQuery = () => !!query.trim();

  const onSearch = () => {
    const [defaultSelectedField] = fields;

    if (hasTextQuery()) {
      const newFilterFields = [
        ...filterFields,
        {
          key: selectedField.prop,
          value: query.trim(),
          label: selectedField.label || selectedField.prop,
          operator: OperatorEnum.REGEX,
        } as IFilter,
      ];
      setSelectedField(defaultSelectedField);
      setQuery('');
      setSearchParams(filterToParams(newFilterFields));
    }
  };

  const onCloseAutocompleteField = () => setAnchorElAutocompleteField(null);

  const onCloseSelectFields = () => {
    setQuery('');
    setAnchorElMenuField(null);
  };

  const onOpenAutocompleteField = event => {
    event.stopPropagation();
    setAnchorElAutocompleteField(event.currentTarget);
  };
  const onOpenSelectFields = event => {
    event.stopPropagation();

    if (disabledFilter) return;

    setAnchorElMenuField(event.currentTarget);
  };
  const onClickAutocompleteField = value => {
    setQuery(value.target.innerText);
    onCloseAutocompleteField();
  };

  const onDeleteFilter = (i: number) => {
    const newFilterFields = filterFields.filter((d, index) => index !== i);
    setSearchParams(filterToParams(newFilterFields));
  };

  const onSelectFields = (field: Field) => {
    onCloseSelectFields();
    setSelectedField(field);
  };

  const onKeyDownTextField = event => {
    if (event.key === 'Enter') onSearch();
  };

  const DeleteValueTextField = hasTextQuery() ? (
    <X onClick={() => setQuery('')} style={{ cursor: 'pointer', marginRight: 8 }} fontSize="small" />
  ) : undefined;

  const MenuField = (
    <>
      <IconButton
        disabled={disabledFilter}
        style={{ marginLeft: 8 }}
        onClick={onOpenSelectFields}
        color="primary"
        aria-label="appliquer filtre"
        component="span">
        <FilterList />
      </IconButton>
      <MenuComp
        id="MenuField"
        anchorEl={anchorElMenuField}
        open={openMenuField}
        onClose={onCloseSelectFields}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
            marginTop: '3ch',
          },
        }}>
        {fields.map(field => (
          <MenuItem key={field.prop} selected={field.prop === selectedField.prop} onClick={() => onSelectFields(field)}>
            {field.label}
          </MenuItem>
        ))}
      </MenuComp>
    </>
  );

  const InputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <IconButton
          sx={{ marginLeft: -1 }}
          disabled={disabledFilter}
          onClick={onSearch}
          color={hasTextQuery() ? 'secondary' : 'primary'}
          component="span">
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment style={{ cursor: 'pointer' }} position="end">
        {DeleteValueTextField}
        {MenuField}
      </InputAdornment>
    ),
  };

  const selectInput = () => {
    if (typeof selectedField.type === 'object') {
      return (
        <>
          <TextField
            onClick={onOpenAutocompleteField}
            onKeyDown={onKeyDownTextField}
            fullWidth
            value={query}
            disabled={disabledFilter}
            InputProps={InputProps}
            placeholder={`${i18n.t('searchBar.search')} ${selectedField.label}`}
            variant="outlined"
          />
          <Popover
            sx={{
              marginLeft: '4ch',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={openAutocompleteField}
            anchorEl={anchorElAutocompleteField}
            onClose={onCloseAutocompleteField}>
            <Box
              key={selectedField.prop}
              sx={{
                p: 1,
                width: '25ch',
              }}>
              {Object.values(selectedField.type).map(type => (
                <Box
                  key={type}
                  onClick={event => onClickAutocompleteField(event)}
                  flexDirection="column"
                  display="flex"
                  sx={{
                    mb: 1,
                    mt: 1,
                  }}>
                  {selectedField.renderOption(type)}
                </Box>
              ))}
            </Box>
          </Popover>
        </>
      );
    }

    return (
      <TextField
        onKeyDown={onKeyDownTextField}
        fullWidth
        disabled={disabledFilter}
        InputProps={InputProps}
        onChange={handleQueryChange}
        value={query}
        placeholder={`${i18n.t('searchBar.search')} ${selectedField.label}`}
        variant="outlined"
      />
    );
  };

  return (
    <Box
      sx={{
        m: 1,
        maxWidth: '100%',
      }}>
      <Box sx={{ m: 1, maxWidth: '100%', width: 500 }}>{selectInput()}</Box>
      <Box
        sx={{
          maxWidth: '100%',
        }}>
        {filterFields.map(({ value, label }, i) => (
          <Chip
            sx={{ m: 0.5 }}
            key={uuidV4()}
            label={`${label}: ${decodeURIComponent(`${value}`)}`}
            onDelete={() => onDeleteFilter(i)}
            deleteIcon={<X fontSize="small" />}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SearchBar;
