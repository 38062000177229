import axios from 'axios';
import useSWR from 'swr';
import { quebecorCafeId, Restaurant } from '../types/restaurant';
import { SwrAction } from '../types/swr';

export const useAllRestaurants = (): SwrAction<Restaurant[]> => {
  const fetcher = (url: string) =>
    axios.get<{ data: Restaurant[]; count: number }>(url).then(res => ({
      restaurants: res.data.data || [],
    }));

  const { data, error, mutate } = useSWR('restaurants', fetcher);
  return {
    data: data?.restaurants.filter(resto => resto.id !== quebecorCafeId) || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
