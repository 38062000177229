import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { LoyaltyListTable } from '../../components/loyalty/LoyaltyListTable';
import useSettings from '../../hooks/useSettings';
import { LoyaltyType } from 'src/components/loyalty/enum/LoyaltyType';

type Props = {
  tab: LoyaltyType.ALL | LoyaltyType.PRIVILEGES | LoyaltyType.REWARD | LoyaltyType.BONUS_POINTS;
};

export const LoyaltyList: FC<Props> = ({ tab }) => {
  const { settings } = useSettings();
  const { i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{i18n.t('loyaltyList.title')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {i18n.t('loyaltyList.listTitle')}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
                <Button color="primary" component={RouterLink} variant="contained" to="/dashboard/loyalties/create" startIcon={<Add />}>
                  {i18n.t('loyaltyList.createButton')}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <LoyaltyListTable tab={tab} />
          </Box>
        </Container>
      </Box>
    </>
  );
};
