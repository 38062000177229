import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import BannerListTable from '../../components/promotion/banner/BannerListTable';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const BannerList: FC = () => {
  const { settings } = useSettings();
  const { i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{i18n.t('promotionBannerList.title')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h3">
                {i18n.t('promotionBannerList.listTitle')}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">
                  {i18n.t('common.dashboard')}
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {i18n.t('promotionBannerList.breadCrumb.title')}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  variant="contained"
                  to="/dashboard/promotions-banners/create"
                  startIcon={<Add />}>
                  {i18n.t('promotionBannerList.createButton')}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <BannerListTable />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannerList;
