import { createContext } from 'react';

interface IConditionsContext {
  applyTo: 'employee' | 'customer';
  hasConditions: boolean;
  setHasConditions: React.Dispatch<React.SetStateAction<boolean>>;
  hasTimeCondition: boolean;
  setHasTimeCondition: React.Dispatch<React.SetStateAction<boolean>>;
  hasRestaurantCondition: boolean;
  setHasRestaurantCondition: React.Dispatch<React.SetStateAction<boolean>>;
  hasRestosDollarsCondition: boolean;
  setHasRestosDollarsCondition: React.Dispatch<React.SetStateAction<boolean>>;
  hasSpecificProductCondition: boolean;
  setHasSpecificProductCondition: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoyaltyConditionsContext = createContext<IConditionsContext>({
  applyTo: 'employee',
  hasConditions: false,
  setHasConditions: () => {},
  hasTimeCondition: false,
  setHasTimeCondition: () => {},
  hasRestaurantCondition: false,
  setHasRestaurantCondition: () => {},
  hasRestosDollarsCondition: false,
  setHasRestosDollarsCondition: () => {},
  hasSpecificProductCondition: false,
  setHasSpecificProductCondition: () => {},
});
