import axios from 'axios';
import useSWR from 'swr';
import { ScopedMutator } from 'swr/dist/types';
import { PromotionBannerForm } from '../pages/dashboard/BannerCreate';
import { ParamsCollector } from '../types/paramsCollector';
import type { Banner } from '../types/banner';
import { BannerPage } from '../types/banner';
import { SwrAction } from '../types/swr';

const getKey = (params: ParamsCollector) => {
  const { page, limit, filter } = params;
  return () => {
    const urlFilters = filter ? `&filter=${filter}` : '';
    return `dashboard/promotion/banner?${`page=${page}&limit=${limit}${urlFilters}`}`;
  };
};

export const deletePromotionBanner = async (id: string): Promise<void> => axios.delete(`dashboard/promotion/banner/${id}`);

export const updatePromotionOrder = async (id: string, order: number): Promise<void> =>
  axios.post(`dashboard/promotion/banner/${id}`, { order });

export const createPromotionBanner = async (mutate: ScopedMutator, form: PromotionBannerForm): Promise<void> => {
  const reader = new FileReader();
  reader.onload = async event => {
    const base64 = (event.target.result as string).split(',')[1];
    if (event.target.result) {
      await mutate(getKey({ limit: 10, page: 0 }), async ({ promotionBanners }: BannerPage) => {
        const response = await axios.post<Banner>('dashboard/promotion/banner', {
          ...form,
          picture: base64,
        });
        return [...promotionBanners, response.data];
      });
    }
  };
  reader.readAsDataURL(form.picture);
};

export const useAllPromotionBanner = (params: ParamsCollector): SwrAction<Banner[]> => {
  const fetcher = (url: string) =>
    axios.get<{ data: Banner[]; count: number }>(url).then(res => ({
      count: res.data.count || 0,
      promotionBanners: res.data.data || [],
    }));

  const { data, error, mutate } = useSWR(getKey(params), fetcher);
  return {
    data: data?.promotionBanners || [],
    count: data?.count || 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
