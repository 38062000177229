import { FormControlLabel, Grid, Switch } from '@mui/material';
import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputText } from 'src/components/common/FormTextField';
import { LoyaltyConditionsContext } from '../context/LoyaltyConditionsContext';

export const SpecificProductCondition: FC = () => {
  const { i18n } = useTranslation();

  const context = useContext(LoyaltyConditionsContext);

  const { hasRestosDollarsCondition, hasSpecificProductCondition, setHasSpecificProductCondition } = context;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={hasSpecificProductCondition}
              data-testid="switch-conditionBonusPoint"
              disabled={hasRestosDollarsCondition}
              onChange={event => setHasSpecificProductCondition(event.target.checked)}
            />
          }
          label={`${i18n.t('loyaltyDetails.form.specificProduct')}`}
        />
      </Grid>
      {hasSpecificProductCondition && (
        <Grid container spacing={2} sx={{ paddingLeft: '65px', paddingTop: '16px' }}>
          <Grid item xs={10}>
            <FormInputText
              label={`${i18n.t('loyaltyDetails.form.mdPlu')}`}
              variant="outlined"
              control={control}
              name="conditions.productConstraint"
              data-testid="input-conditionSpecificProduct"
              required={hasSpecificProductCondition}
              error={!!errors.conditions?.productConstraint}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
