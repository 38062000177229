import { FormControlLabel, Grid, Switch } from '@mui/material';
import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputNumberDecimal } from 'src/components/common/FormInputNumberDecimal';
import { LoyaltyConditionsContext } from '../context/LoyaltyConditionsContext';

export const RestosDollarsCondition: FC = () => {
  const { i18n } = useTranslation();

  const context = useContext(LoyaltyConditionsContext);

  const { hasTimeCondition, hasRestaurantCondition, hasSpecificProductCondition, hasRestosDollarsCondition, setHasRestosDollarsCondition } =
    context;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={hasRestosDollarsCondition}
              data-testid="switch-conditionBonusPoint"
              disabled={hasTimeCondition || hasRestaurantCondition || hasSpecificProductCondition}
              onChange={event => setHasRestosDollarsCondition(event.target.checked)}
            />
          }
          label={`${i18n.t('loyaltyDetails.form.useOfRestoDollar')}`}
        />
      </Grid>
      {hasRestosDollarsCondition && (
        <Grid container spacing={2} sx={{ paddingLeft: '65px', paddingTop: '16px' }}>
          <Grid item xs={10}>
            <FormInputNumberDecimal
              min={1}
              label={`${i18n.t('loyaltyDetails.form.minimumValue')}`}
              variant="outlined"
              control={control}
              name="conditions.restoDollarsConstraint"
              data-testid="input-conditionRestosDollars"
              required={hasRestosDollarsCondition}
              error={!!errors.conditions?.restoDollarsConstraint}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
