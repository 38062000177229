import i18next from 'i18next';
import Label, { LabelColor } from '../Label';

export type LoyaltyStatus = number;

export const getLoyaltyStatusLabel = (status: LoyaltyStatus): JSX.Element => {
  const map: Record<LoyaltyStatus, { color: LabelColor; text: string }> = {
    0: {
      color: 'success',
      text: i18next.t('loyaltyList.inProgress'),
    },
    1: {
      color: 'secondary',
      text: i18next.t('loyaltyList.upcoming'),
    },
    2: {
      color: 'error',
      text: i18next.t('loyaltyList.expired'),
    },
    3: {
      color: 'warning',
      text: i18next.t('loyaltyList.missingInfos'),
    },
  };

  const { text, color } = map[status];

  return (
    <Label sx={{ p: 1, textTransform: 'none' }} color={color}>
      {text}
    </Label>
  );
};
