import { createContext, useContext, useState } from 'react';

type LoadingContextType = {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
};

const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setLoading: () => {},
});

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>;
};
