import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller, Validate } from 'react-hook-form';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Tried to type correctly but can't seem to make it work with library.
  control: any;
  validate?: Validate<unknown> | Record<string, Validate<unknown>>;
  min?: number;
  max?: number;
} & TextFieldProps;

const maxTwoDecimalNumber = /^\d*\.?\d{0,2}$/;

export const FormInputNumberDecimal: FC<Props> = (props): JSX.Element => {
  const { control, name, required, validate, min, max, ...rest } = props;
  return (
    <Controller
      rules={{ required: true, validate, min, max }}
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          {...rest}
          onBlur={event => {
            const { value } = event.target;
            if (maxTwoDecimalNumber.test(value) && Number.isFinite(+value)) field.onChange(+value);
          }}
          value={field.value || ''}
          onChange={event => {
            const { value } = event.target;
            if (maxTwoDecimalNumber.test(value)) field.onChange(value);
          }}
          required={required}
        />
      )}
    />
  );
};
