import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAllRestaurants } from 'src/actions/restaurantActions';
import { LoyaltyConditionsContext } from '../context/LoyaltyConditionsContext';

export const RestaurantCondition: FC = () => {
  const { i18n } = useTranslation();
  const context = useContext(LoyaltyConditionsContext);
  const { hasRestosDollarsCondition, hasRestaurantCondition, setHasRestaurantCondition } = context;
  const { control } = useFormContext();
  const { data: restaurants } = useAllRestaurants();

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={hasRestaurantCondition}
              data-testid="switch-conditionRestaurants"
              disabled={hasRestosDollarsCondition}
              onChange={event => setHasRestaurantCondition(event.target.checked)}
            />
          }
          label={`${i18n.t('loyaltyDetails.form.specificRestaurants')}`}
        />
      </Grid>
      {hasRestaurantCondition && (
        <Grid container spacing={2} sx={{ paddingLeft: '65px', paddingTop: '16px' }}>
          <Grid item xs={10}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup row>
                <Controller
                  name="conditions.restaurantConstraint"
                  rules={{ required: true }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      {restaurants.map(r => (
                        <FormControlLabel
                          key={r.id}
                          label={r.name}
                          sx={{ color: fieldState.invalid ? '#f44336' : '' }}
                          control={
                            <Checkbox
                              color={fieldState.invalid ? 'error' : 'secondary'}
                              value={r.id}
                              data-testid={`checkbox-restaurant-${r.id}`}
                              checked={(field.value || []).some(existingValue => existingValue === r.id)}
                              onChange={(event, checked) => {
                                if (checked) {
                                  field.onChange([...(field.value || []), event.target.value]);
                                } else {
                                  field.onChange((field.value || []).filter(value => value !== event.target.value));
                                }
                              }}
                            />
                          }
                        />
                      ))}
                    </>
                  )}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};
