import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from '@mui/material';
import i18n from 'i18next';
import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { deleteBonusPoints } from 'src/actions/bonusPointsActions';
import { deletePrivilege } from 'src/actions/privilegeActions';
import { useLoadingContext } from 'src/contexts/LoadingContext';
import { useAllLoyalties } from '../../actions/loyaltyActions';
import { useAllRestaurants } from '../../actions/restaurantActions';
import PencilAltIcon from '../../icons/PencilAlt';
import { getFormattedDate } from '../../utils/dateUtils';
import Scrollbar from '../Scrollbar';
import { getLoyaltyStatusLabel } from './GetLoyaltyStatusLabel';
import { LoyaltyType } from './enum/LoyaltyType';

type LoyaltyListTabs = LoyaltyType.ALL | LoyaltyType.PRIVILEGES | LoyaltyType.REWARD | LoyaltyType.BONUS_POINTS;

interface ITab {
  label: string;
  value: LoyaltyListTabs;
}

const getTabs = (): ITab[] => [
  {
    label: i18n.t('loyaltyList.tabs.privileges'),
    value: LoyaltyType.PRIVILEGES,
  },
  {
    label: i18n.t('loyaltyList.tabs.bonusPoints'),
    value: LoyaltyType.BONUS_POINTS,
  },
];

type Props = {
  tab: LoyaltyType.ALL | LoyaltyType.PRIVILEGES | LoyaltyType.REWARD | LoyaltyType.BONUS_POINTS;
};

export const LoyaltyListTable: FC<Props> = ({ tab }) => {
  const navigate = useNavigate();
  const tabs = useMemo(() => getTabs(), []);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogDeleteContext, setDialogDeleteContext] = useState<{ id: string; title: string }>();
  const { data: loyaltyTemplates, count, mutate, isLoading } = useAllLoyalties(tab, { page, limit, filter: '' });
  const { setLoading } = useLoadingContext();
  useAllLoyalties(tab, { page: page + 1, limit, filter: '' });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const { data: restaurantList = [] } = useAllRestaurants();
  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleTabsChange = (_, value: LoyaltyListTabs): void => {
    navigate(`../${value}`);
    setPage(0);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value, 10);
    setPage(Math.floor((page * limit) / newLimit));
    setLimit(newLimit);
  };

  const handleClickOpen = ({ id, title }: { id: string; title: string }) => {
    setDialogDeleteContext({ id, title });
    setIsDialogOpen(true);
  };

  const handleCloseDelete = () => {
    setIsDialogOpen(false);
    setDialogDeleteContext(null);
  };

  const handleDeleteClick = async () => {
    handleCloseDelete();

    switch (tab) {
      case LoyaltyType.BONUS_POINTS:
        await deleteBonusPoints(dialogDeleteContext.id);
        break;
      case LoyaltyType.PRIVILEGES:
        await deletePrivilege(dialogDeleteContext.id);
        break;
      default:
        console.error('Wrong tab value');
        break;
    }

    await mutate([...loyaltyTemplates.filter(loyaltyTemplate => loyaltyTemplate.id !== dialogDeleteContext.id)]);
  };

  return (
    <Card>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{i18n.t('loyaltyList.dialogDelete.title', { name: dialogDeleteContext?.title })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{i18n.t('loyaltyList.dialogDelete.description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="dialog-delete-button-no" onClick={handleCloseDelete}>
            {i18n.t('loyaltyList.dialogDelete.no')}
          </Button>
          <Button data-testid="dialog-delete-button-yes" onClick={handleDeleteClick}>
            {i18n.t('loyaltyList.dialogDelete.yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Tabs
        indicatorColor="secondary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={tab}
        variant="scrollable">
        {tabs.map(_tab => (
          <Tab key={_tab.value} label={_tab.label} value={_tab.value} />
        ))}
      </Tabs>
      <Divider />

      <Scrollbar>
        <Box sx={{ minWidth: 1150 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('loyaltyList.title')}</TableCell>
                <TableCell width={115}>{i18n.t('loyaltyList.startDate')}</TableCell>
                <TableCell width={115}>{i18n.t('loyaltyList.endDate')}</TableCell>
                <TableCell>{i18n.t('loyaltyList.status')}</TableCell>
                <TableCell>{i18n.t('loyaltyList.restaurant')}</TableCell>
                <TableCell style={{ width: 150 }} align="center">
                  {i18n.t('loyaltyList.actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loyaltyTemplates.map(({ id, endDate, title, startDate, status, restaurants = [] }) => {
                const canDelete = new Date(startDate) > new Date();
                return (
                  <TableRow hover key={id} data-testid="row-data">
                    <TableCell data-testid="title">{title}</TableCell>
                    <TableCell data-testid="startDate">{getFormattedDate(startDate)}</TableCell>
                    <TableCell data-testid="endDate">{getFormattedDate(endDate)}</TableCell>
                    <TableCell data-testid="status">{getLoyaltyStatusLabel(status)}</TableCell>
                    <TableCell data-testid="restaurant">
                      {restaurants
                        .map(restaurantId => restaurantList.find(restaurant => restaurant.id === restaurantId)?.name)
                        .filter(x => !!x)
                        .join(', ')}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton data-testid="delete-button" onClick={() => handleClickOpen({ id, title })} disabled={!canDelete}>
                        <Delete fontSize="small" htmlColor={canDelete ? '#F34236' : 'currentColor'} />
                      </IconButton>
                      <IconButton component={RouterLink} to={`/dashboard/loyalties/${tab}/${id}`} aria-label="arrowLink">
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 40]}
      />
    </Card>
  );
};
