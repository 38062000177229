import { Box, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Loyalty } from 'src/types/loyalty';

interface MaxUsagesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Tried to type correctly but can't seem to make it work with library.
  useFormMethods: UseFormReturn<Loyalty, any>;
  hasConditions: boolean;
}

export const MaxUsages: FC<MaxUsagesProps> = ({ useFormMethods, hasConditions }) => {
  const { control, setValue, getValues } = useFormMethods;
  const { i18n } = useTranslation();

  useEffect(() => {
    if (hasConditions) setValue('maxUsages', 1);
  }, [hasConditions, getValues, setValue]);

  const handleMaxUsagesRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    switch (inputValue) {
      case 'number':
        setValue('maxUsages', 1, { shouldValidate: true });
        break;
      case 'infinite':
        setValue('maxUsages', -1);
        break;
      default:
        break;
    }
  };

  return (
    <Controller
      name="maxUsages"
      rules={{ validate: value => value === -1 || value > 0 }}
      control={control}
      render={({ field, fieldState }) => (
        <Tooltip title={`${i18n.t('loyaltyDetails.form.maxUsageDisabled')}`} followCursor disableHoverListener={!hasConditions}>
          <Grid item xs={4}>
            <RadioGroup name="maxUsagesChoice" onChange={handleMaxUsagesRadioChange} row sx={{ alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  // @ts-ignore -- Typescript doesn't like the data-testid attribute.
                  inputProps={{ 'data-testid': 'usage-number-radio' }}
                  value="number"
                  checked={field.value !== -1}
                  disabled={hasConditions}
                  color="secondary"
                />
                <TextField
                  sx={{ width: '150px' }}
                  type="text"
                  error={fieldState.invalid}
                  value={field.value === -1 ? '' : field.value || ''}
                  onChange={e => {
                    if (Number.isFinite(+e.target.value)) field.onChange(+e.target.value);
                    if (e.target.value === '') field.onChange('');
                  }}
                  disabled={hasConditions || getValues('maxUsages') === -1}
                  inputProps={{ 'data-testid': 'usage-policy-input' }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{`${i18n.t('loyaltyDetails.form.usage')}`}</InputAdornment>,
                  }}
                />
              </Box>

              <FormControlLabel
                sx={{ ml: 2.7 }}
                value="infinite"
                control={
                  <Radio
                    // @ts-ignore -- Typescript doesn't like the data-testid attribute.
                    inputProps={{ 'data-testid': 'usage-infinite-radio' }}
                    id="infinite-radio"
                    checked={field.value === -1}
                    disabled={hasConditions}
                    color="secondary"
                  />
                }
                label="∞"
              />
            </RadioGroup>
          </Grid>
        </Tooltip>
      )}
    />
  );
};
