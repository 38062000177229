import { Chip, Grid, SxProps, Theme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isValid, parseISO } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WeekDays } from '../../../constants';

const weekDays = [
  WeekDays.SUNDAY,
  WeekDays.MONDAY,
  WeekDays.TUESDAY,
  WeekDays.WEDNESDAY,
  WeekDays.THURSDAY,
  WeekDays.FRIDAY,
  WeekDays.SATURDAY,
];

interface WeekdayChipsProps {
  sx?: SxProps<Theme>;
  validDaysInputName: string;
  startTimeInputName: string;
  endTimeInputName: string;
}

export const WeekDaySelection: FC<WeekdayChipsProps> = ({ sx, validDaysInputName, startTimeInputName, endTimeInputName }) => {
  const { i18n } = useTranslation();
  const { control, setValue, watch, trigger } = useFormContext();
  const selectedDays = watch(validDaysInputName);
  const startTime = watch(startTimeInputName);

  const handleDayClick = (index: number) => {
    const selectedDaysOnClick = selectedDays || [];
    const isSelected = selectedDaysOnClick.includes(index);
    const newSelectedDays = isSelected ? selectedDaysOnClick.filter(dayIndex => dayIndex !== index) : [...selectedDaysOnClick, index];

    setValue(validDaysInputName, newSelectedDays);
    trigger();
  };

  return (
    <Grid container spacing={2} sx={{ ...sx }}>
      <Grid item xs={12}>
        <Controller
          name={validDaysInputName}
          rules={{ required: true }}
          control={control}
          render={({ fieldState }) => (
            <>
              {weekDays.map((day: number) => (
                <Chip
                  color={fieldState.invalid ? 'error' : 'secondary'}
                  variant={selectedDays?.includes(day) ? 'filled' : 'outlined'}
                  label={i18n.t(`weekDays.${day}`)}
                  key={day}
                  onClick={() => handleDayClick(day)}
                  style={{ marginRight: '9px' }}
                />
              ))}
            </>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name={startTimeInputName}
          rules={{
            required: true,
            validate: value => {
              const date = typeof value === 'string' ? parseISO(value) : value;
              return isValid(date);
            },
          }}
          control={control}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                sx={{ width: '100%' }}
                value={field.value ? new Date(field.value) : null}
                ampm={false}
                label={i18n.t('loyaltyDetails.form.startTime')}
                slotProps={{
                  textField: {
                    error: fieldState.invalid,
                    placeholder: 'hh:mm',
                    // @ts-ignore
                    'data-testid': 'input-startTime',
                  },
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name={endTimeInputName}
          rules={{
            required: true,
            validate: value => {
              const date = typeof value === 'string' ? parseISO(value) : value;
              return isValid(date);
            },
          }}
          control={control}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    error: fieldState.invalid,
                    // @ts-ignore
                    'data-testid': 'input-endTime',
                  },
                }}
                value={field.value ? new Date(field.value) : null}
                ampm={false}
                data-testid="input-endTime"
                label={i18n.t('loyaltyDetails.form.endTime')}
                minTime={new Date(startTime)}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </Grid>
  );
};
