export const quebecorCafeId = '5f958e29-e450-4ff3-8ad9-233bc54b6cbe';

export type Restaurant = {
  id: string;
  workingSiteCode: string;
  name: string;
  phoneNumber: string;
  website: string;
  description: string;
  mevs: number[];
  address?: string;
  bookingId?: string;
  orderPage?: string;
};
