// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypeField = string | number | Date | Array<any> | { [value: string]: string };
export type Field<T = TypeField> = {
  prop: string;
  label: string;
  type?: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderOption?: (type: any) => JSX.Element;
};

export type IFilter = {
  key: string;
  operator?: OperatorEnum;
  label?: string;
  value: string | number | boolean;
  hasNotRegexOperator?: boolean;
};

export enum OperatorEnum {
  EQUAL = '$eq',
  NOT_EQUAL = '$ne',
  GREATER_THAN = '$gt',
  GREATER_THAN_OR_EQUAL = '$gte',
  IN = '$in',
  LESS_THAN = '$lt',
  LESS_THAN_OR_EQUAL = '$lte',
  NOT_IN = '$nin',
  AND = '$and',
  NOT = '$not',
  NOT_OR = '$nor',
  OR = '$or',
  REGEX = '$regex',
}
