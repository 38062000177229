import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { Auth0Client, LogoutOptions } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
import jwt_decode from 'jwt-decode';
import type { User } from '../types/user';
import axios from 'axios';

let auth0Client: Auth0Client | null = null;

interface Token {
  permissions: string[];
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends State {
  platform: 'Auth0';
  loginWithPopup: (options?) => Promise<void>;
  logout: (options?: LogoutOptions) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: (options?: LogoutOptions) => Promise.resolve(options)
});

export const AuthProvider: FC<AuthProviderProps> = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
        });

        await auth0Client.checkSession();
        axios.defaults.baseURL = process.env.REACT_APP_URL_BACKEND;
        axios.interceptors.request.use(async config => {
          config.headers['x-api-dash'] = await auth0Client.getTokenSilently();
          return config;
        });

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();

          const accessToken = await auth0Client.getTokenSilently();
          const { permissions } = jwt_decode<Token>(accessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: user.name,
                permissions,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (options): Promise<void> => {
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();

      const accessToken = await auth0Client.getTokenSilently();
      const { permissions } = jwt_decode<Token>(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            permissions,
          },
        },
      });
    }
  };

  const logout = (options?: LogoutOptions): void => {
    delete axios.defaults.headers.common['x-api-dash'];
    auth0Client.logout(options);
    dispatch({
      type: 'LOGOUT',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
