import { Card, FormControlLabel, Grid, InputAdornment, Switch, Typography } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputNumberDecimal } from 'src/components/common/FormInputNumberDecimal';
import { FormInputText } from 'src/components/common/FormTextField';
import { LoyaltyConditionsContext } from '../context/LoyaltyConditionsContext';
import { RestaurantCondition } from './RestaurantCondition';
import { TimeCondition } from './TimeCondition';
import { RestosDollarsCondition } from './RestosDollarsCondition';
import { SpecificProductCondition } from './SpecificProductCondition';

export const LoyaltyConditions: FC = () => {
  const { i18n } = useTranslation();

  const context = useContext(LoyaltyConditionsContext);

  const {
    applyTo,
    hasConditions,
    setHasConditions,
    hasTimeCondition,
    hasRestaurantCondition,
    hasRestosDollarsCondition,
    hasSpecificProductCondition,
  } = context;
  const {
    register,
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  register('conditions.atLeastOne', {
    // eslint-disable-next-line no-unused-vars
    validate: _ => !hasConditions || hasTimeCondition || hasRestaurantCondition || hasRestosDollarsCondition || hasSpecificProductCondition,
  });

  useEffect(() => {
    if (hasTimeCondition || hasRestaurantCondition || hasRestosDollarsCondition || hasSpecificProductCondition)
      clearErrors('conditions.atLeastOne');
  }, [hasTimeCondition, hasRestaurantCondition, hasRestosDollarsCondition, hasSpecificProductCondition, clearErrors]);

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={hasConditions}
              disabled={applyTo === 'employee'}
              onChange={event => setHasConditions(event.target.checked)}
            />
          }
          label={`${i18n.t('loyaltyDetails.form.lockPrivilege')}`}
        />
      </Grid>

      {hasConditions && (
        <Card>
          <Grid container spacing={2} sx={{ padding: '24px' }}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {i18n.t('loyaltyDetails.form.informations')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormInputText
                sx={{ width: '100%' }}
                label={`${i18n.t('loyaltyDetails.form.title')}`}
                variant="outlined"
                control={control}
                data-testid="input-conditionTitle"
                name="conditions.title"
                required={hasConditions}
                error={!!errors.conditions?.title}
              />
            </Grid>
            <Grid item xs={8}>
              <FormInputText
                sx={{ width: '100%' }}
                label={`${i18n.t('loyaltyDetails.form.description')}`}
                variant="outlined"
                control={control}
                name="conditions.description"
                data-testid="input-conditionDesc"
                required={hasConditions}
                error={!!errors.conditions?.description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                sx={{ width: '100%' }}
                label={`${i18n.t('loyaltyDetails.form.termsOfUse')}`}
                variant="outlined"
                control={control}
                data-testid="input-conditionTermsOfUse"
                name="conditions.termsOfUse"
                error={!!errors.conditions?.termsOfUse}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {i18n.t('loyaltyDetails.form.conditions')}
              </Typography>
            </Grid>

            <Grid item container rowSpacing={2} xs={12}>
              {errors.conditions?.atLeastOne && (
                <Typography color="error" variant="subtitle2">
                  {i18n.t('loyaltyDetails.form.atLeastOneCondition')}
                </Typography>
              )}
              <TimeCondition />
              <RestaurantCondition />
              <RestosDollarsCondition />
              <SpecificProductCondition />
            </Grid>

            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {i18n.t('loyaltyDetails.form.availability')}
              </Typography>
              <Typography color="textPrimary" sx={{ fontWeight: 'normal', fontSize: '16px' }}>
                {i18n.t('loyaltyDetails.form.availabilityText')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormInputNumberDecimal
                min={1}
                label={`${i18n.t('loyaltyDetails.form.validFor')}`}
                variant="outlined"
                control={control}
                name="conditions.daysOfAvailability"
                data-testid="input-conditionAvailability"
                required={hasConditions}
                error={!!errors.conditions?.daysOfAvailability}
                InputProps={{
                  endAdornment: <InputAdornment position="end">jours</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};
